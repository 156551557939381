<template>
  <div class="py-5 container-fluid">
    <section class="py-3">
        <div class="mt-4 row">
          <div class="col-12">

            <template v-if="beertaps != null">
              

              <div class="mb-4 p-3 card" v-for="establishment, index in beertaps" :key="index">

                <div class="p-3 pb-0 card-header">
                  <h4 class="mb-1" v-if="establishment[0].tap.name == null">
                    Sem PDV
                  </h4>
                  <h4 class="mb-1" v-else>
                    {{establishment[0].tap.name}}
                  </h4>
                </div>

                <draggable class="dragArea list-group w-full" 
                  @end="orderUpdate(index)"
                  :sort="true"
                  :list="beertaps[index]"
                  >

                  <div class="mb-4 mt-4" v-for="data in establishment" :key="data.tap.id">
                      <complex-project-card
                      :logo="data.beer_logo ? data.beer_logo  : 'https://partner.rtn.com.br/images/iris-hor.png'"
                      :tap="data.tap"
                      :beer="data.beer"
                      :beer_manufacturer="data.beer_manufacturer"
                      :partner_name="data.partner_name"
                      title="Slack Bot"
                      date-time="02.03.22"
                      :dropdown="[
                          {
                          label: 'Editar torneira',
                          route: '/beertap/edit/' + data.tap.id,
                          },
                      ]"
                      />
                    
                  </div>
                </draggable>
              </div>

            </template>

          </div>
        </div>
    </section>
  </div>
</template>

<script>
import slackLogo from "@/assets/img/small-logos/logo-slack.svg";
import xdLogo from "@/assets/img/small-logos/logo-xd.svg";
import ComplexProjectCard from "./components/ComplexProjectCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';
import { VueDraggableNext } from 'vue-draggable-next'

export default {
  name: "ProfileTeam",
  components: {
    ComplexProjectCard,
    draggable: VueDraggableNext
  },
  data() {
    return {
      showMenu: false,
      slackLogo,
      xdLogo,
      beertaps:null,
    };
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();

    this.$swal.showLoading()

    api
      .get('/dashboard/get-beertap')
      .then((response) => {
        this.beertaps = response.data;

        this.$swal.close()

        console.log('beertaps');
        console.log(this.beertaps);

      })
      .catch((error) => {

        this.$swal.close()

        console.log("erro: ");
        console.log(error);

      }); 

  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
  methods: {
    orderUpdate(index){
      console.log("teste arrastar: ", index);
      console.log(this.beertaps[index]);

      let order = [];

      this.beertaps[index].forEach(element => {
        order.push(element.tap.id);
      })

      console.log("order: ");
      console.log(order);

      api
        .post('/beertap/order-update', order)
        .then((response) => {
          console.log('order-update');
          console.log(response.data);
        })
        .catch((error) => {
          console.log("erro: ");
          console.log(error);
        }); 

    }
  }
};
</script>
